import logo from './vera_race.jpg';
import instagram from './instagram.jpeg';
import googleMaps from './google-maps.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Site em construção.</h1>
        <img src={logo} className="App-logo" alt="logo" />
        
        <div className="links">
          <a
            href="https://www.instagram.com/vera_race/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instagram} alt="instagram" className="link-logo instagram-logo" />
          </a>

          <a
            href="https://goo.gl/maps/3xcsxga137y9Tspc8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={googleMaps} alt="google-maps" className="link-logo" />
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
